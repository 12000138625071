import { UserMigrationDefaults } from '@local/api/documents'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { useUserMigration } from '../UserMigrationContextProvider'
import { useApolloClient } from '@apollo/client'
import { useDeferMigrationMutation } from '../../hooks'
import { StartView } from './StartView'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { redirectMigratedUser } from '../functions'

export const Start = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserMigration()
  const { mutate: deferMigration } = useDeferMigrationMutation()
  const urlParams = new URLSearchParams(window.location.search)
  const returnUrl = urlParams.get('returnUrl')
  const [loading, setLoading] = React.useState(false)
  const { showErrorSnackBar } = useSnackBar()

  useEffect(() => {
    if (returnUrl && state.returnUrl !== returnUrl) {
      dispatch({ type: 'set-return-url', returnUrl: returnUrl })
    }
  }, [returnUrl])

  const handleContinue = useCallback(() => {
    const queryDefaults = async () => {
      setLoading(true)
      const result = await client.query({
        query: UserMigrationDefaults
      })

      const defaults = result.data?.userMigration?.defaults

      switch (defaults?.__typename) {
        case 'UserMigrationDefaults':
          dispatch({
            type: 'migration-defaults',
            email: defaults?.email || undefined,
            source: defaults?.source || 'NEW'
          })
          break
        case 'UserMigrationFailed':
          showErrorSnackBar(defaults.message)
          break
        case 'UserAlreadyMigrated':
          redirectMigratedUser()
          break
        default:
          showErrorSnackBar('An error occurred. Please try again later.')
      }
      setLoading(false)
    }
    queryDefaults()
  }, [client, dispatch, showErrorSnackBar])

  const handleDeferMigration = () => {
    deferMigration(
      { returnUrl },
      {
        onSuccess: (data) => {
          if (data) {
            window.location.href = data
          }
        }
      }
    )
  }

  return (
    <StartView
      loading={loading}
      onContinue={handleContinue}
      onDeferMigration={handleDeferMigration}
    />
  )
}
