import React from 'react'
import { Layout } from '../layout'
import { Logo } from '../logo'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '@local/translations'

export const EmailConfirmedView = (props: {
  email: string
  onContinue: () => void
}) => {
  const { email, onContinue } = props
  const { t } = useTranslation()

  return (
    <Layout>
      <div className='p-10 flex flex-col items-center'>
        <Logo />
        <h1
          className='type-headline-2 mt-6'
          data-testid='email-confirmed-header'
        >
          {t('email.confirmed.youAreAllSetUp')}
        </h1>
        <p className='mt-8 mb-8 text-center max-w-sm'>
          <span className='font-bold' data-testid='email'>
            {email}
          </span>{' '}
          {t('email.confirmed.isReadyToBeUsed')}
        </p>
        <CelebrationIllustration />
      </div>
      <div className='shadow p-6 flex justify-end'>
        <Button onClick={onContinue} type='button' testId='continue'>
          {t('continue')}
        </Button>
      </div>
    </Layout>
  )
}
