import { graphql } from '../generated/gql'

export const UserMigrationDefaults = graphql(/* GraphQL */ `
  query userMigrationDefaults {
    userMigration {
      defaults {
        __typename
        ... on UserMigrationDefaults {
          email
          source
        }
        ... on UserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationFailed {
          message
        }
      }
    }
  }
`)

export const SendAccountVerificationEmail = graphql(/* GraphQL */ `
  mutation sendAccountVerificationEmail(
    $email: String!
    $idempotencyKey: String!
  ) {
    userMigration {
      sendAccountVerificationEmail(
        email: $email
        idempotencyKey: $idempotencyKey
      ) {
        __typename
        ... on UserMigrationFailed {
          message
        }
        ... on UserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationVerificationEmailSent {
          email
        }
      }
    }
  }
`)

export const VerifyAccountEmail = graphql(/* GraphQL */ `
  query verifyAccountEmail($email: String!) {
    userMigration {
      verifyAccountEmail(email: $email) {
        __typename
        ... on UserMigrationEmailVerified {
          toastIdentityGuid
          verifiedTime
          toastAccountType
        }
        ... on UserMigrationFailed {
          message
        }
        ... on UserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
      }
    }
  }
`)

export const ConfirmAccountVerificationEmail = graphql(/* GraphQL */ `
  mutation confirmAccountVerificationEmail(
    $email: String!
    $confirmationKey: String!
  ) {
    userMigration {
      confirmAccountVerificationEmail(
        email: $email
        confirmationKey: $confirmationKey
      ) {
        __typename
        ... on UserMigrationEmailVerified {
          toastIdentityGuid
          verifiedTime
          toastAccountType
        }
        ... on UserMigrationEmailNotVerified {
          toastIdentityGuid
          toastAccountType
        }
        ... on UserMigrationFailed {
          message
        }
        ... on UserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
      }
    }
  }
`)

export const Migrate = graphql(/* GraphQL */ `
  mutation migrate($email: String!, $password: String!) {
    userMigration {
      migrate(email: $email, password: $password) {
        __typename
        ... on UserMigrationFailed {
          message
        }
        ... on UserMigrationSuccess {
          userId
          toastIdentityGuid
          email
        }
      }
    }
  }
`)
