import * as React from 'react'
import {
  ArrowForwardIcon,
  CheckCircleSelectedFillIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Layout } from '../layout'
import { useTranslation } from '@local/translations'

const NumberedListItem = (props: {
  number: number
  children: React.ReactNode
}) => {
  const { number, children } = props
  return (
    <div>
      <span className='h-4 w-4 rounded-full text-white bg-primary text-center p-3 inline-grid place-content-center mr-2 mb-4'>
        {number}
      </span>
      {children}
    </div>
  )
}

const CheckmarkListItem = (props: { children: React.ReactNode }) => {
  const { children } = props

  return (
    <div className='mb-4'>
      <CheckCircleSelectedFillIcon
        accessibility='decorative'
        className='text-success mr-1 '
      />
      {children}
    </div>
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <div className='space-y-6'>
      <div className='font-semibold mb-4'>{t('start.whatYouWillNeedToDo')}</div>
      <div>
        <NumberedListItem number={1}>{t('start.question1')}</NumberedListItem>
        <NumberedListItem number={2}>{t('start.question2')}</NumberedListItem>
        <NumberedListItem number={3}>{t('start.question3')}</NumberedListItem>
      </div>
    </div>
  )
}

export const StartView = (props: {
  loading: boolean
  onDeferMigration: () => void
  onContinue: () => void
}) => {
  const { t } = useTranslation()

  const { onDeferMigration, onContinue, loading } = props

  return (
    <Layout questions={<Questions />}>
      <div className='p-10'>
        <div className='w-2/3'>
          <h1 className='type-headline-2 mb-4'>{t('start.heading')}</h1>
          <p>{t('start.body')}</p>
        </div>
        <hr className='mt-6 mb-6 h-px border-1 bg-gray-125' />
        <div>
          <div className='font-semibold mb-4'>{t('start.listHeading')}</div>
          <div>
            <CheckmarkListItem>{t('start.listItem1')}</CheckmarkListItem>
            <CheckmarkListItem>{t('start.listItem2')}</CheckmarkListItem>
            <CheckmarkListItem>{t('start.listItem3')}</CheckmarkListItem>
          </div>
        </div>
      </div>
      <div className='shadow p-6 flex justify-end'>
        <Button
          className='mr-4'
          testId='defer-migration'
          variant='secondary'
          onClick={onDeferMigration}
          disabled={loading}
        >
          {t('skipForNow')}
        </Button>
        <Button
          onClick={onContinue}
          testId='continue'
          name='continue'
          role='button'
          type='button'
          disabled={loading}
          iconRight={<ArrowForwardIcon aria-label={t('continue')} />}
        >
          {t('continue')}
        </Button>
      </div>
    </Layout>
  )
}
