import * as React from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Layout } from '../layout'
import { Logo } from '../logo'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { EmailVerified as EmailVerifiedState } from '../state'
import { useTranslation } from '@local/translations'

export const EmailVerified = () => {
  const state = useUserMigrationState<EmailVerifiedState>()
  const { t } = useTranslation()

  return (
    <Layout>
      <div className='p-10 flex flex-col items-center'>
        <Logo />
        <h1 className='type-headline-2 mt-6'>
          {t('email.verified.emailVerified')}
        </h1>
        <p className='mt-8 mb-8 text-center max-w-sm'>
          <span className='font-bold' data-testid='email'>
            {state.email}
          </span>{' '}
          {t('email.verified.isReadyToBeUsed')}
        </p>
        <p className='mb-8 text-center max-w-sm'>
          {t('email.verified.youCanCloseThisWindow')}
        </p>
        <CelebrationIllustration />
      </div>
    </Layout>
  )
}
