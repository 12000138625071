import React from 'react'
import { Layout } from '../layout'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon, ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { useTranslation } from '@local/translations'

export const VerifyEmailView = (props: {
  email: string
  onEditEmail: () => void
  onResendEmail: () => void
}) => {
  const { email, onEditEmail, onResendEmail } = props
  const { t } = useTranslation()

  return (
    <Layout>
      <div className='p-10'>
        <div className='type-headline-2 pt-10'>
          {t('email.verify.checkYourEmail')}
        </div>
        <div className='pt-6 flex'>
          <div className='flex-auto w-1/2'>
            {t('email.verify.weHaveSentAnEmailTo')}{' '}
            <span className='font-bold'>{email}</span>{' '}
            {t('email.verify.toVerifyYourEmailAndConnectYourAccounts')}
          </div>
          <div className='flex-auto text-right'>
            <Button
              onClick={onResendEmail}
              as='a'
              variant='link'
              iconRight={<ArrowForwardIcon accessibility='decorative' />}
            >
              {t('resendEmail')}
            </Button>
          </div>
        </div>
        <hr className='mt-6 h-px border-1 bg-gray-125 mb-6' />
        <div className='flex justify-end'>
          <Button
            onClick={onEditEmail}
            type='button'
            variant='secondary'
            iconLeft={<AddIcon accessibility='decorative' />}
          >
            {t('editEmail')}
          </Button>
        </div>
      </div>
    </Layout>
  )
}
