/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query userMigrationDefaults {\n    userMigration {\n      defaults {\n        __typename\n        ... on UserMigrationDefaults {\n          email\n          source\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n      }\n    }\n  }\n": types.UserMigrationDefaultsDocument,
    "\n  mutation sendAccountVerificationEmail(\n    $email: String!\n    $idempotencyKey: String!\n  ) {\n    userMigration {\n      sendAccountVerificationEmail(\n        email: $email\n        idempotencyKey: $idempotencyKey\n      ) {\n        __typename\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n        ... on UserMigrationVerificationEmailSent {\n          email\n        }\n      }\n    }\n  }\n": types.SendAccountVerificationEmailDocument,
    "\n  query verifyAccountEmail($email: String!) {\n    userMigration {\n      verifyAccountEmail(email: $email) {\n        __typename\n        ... on UserMigrationEmailVerified {\n          toastIdentityGuid\n          verifiedTime\n          toastAccountType\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n      }\n    }\n  }\n": types.VerifyAccountEmailDocument,
    "\n  mutation confirmAccountVerificationEmail(\n    $email: String!\n    $confirmationKey: String!\n  ) {\n    userMigration {\n      confirmAccountVerificationEmail(\n        email: $email\n        confirmationKey: $confirmationKey\n      ) {\n        __typename\n        ... on UserMigrationEmailVerified {\n          toastIdentityGuid\n          verifiedTime\n          toastAccountType\n        }\n        ... on UserMigrationEmailNotVerified {\n          toastIdentityGuid\n          toastAccountType\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n      }\n    }\n  }\n": types.ConfirmAccountVerificationEmailDocument,
    "\n  mutation migrate($email: String!, $password: String!) {\n    userMigration {\n      migrate(email: $email, password: $password) {\n        __typename\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserMigrationSuccess {\n          userId\n          toastIdentityGuid\n          email\n        }\n      }\n    }\n  }\n": types.MigrateDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userMigrationDefaults {\n    userMigration {\n      defaults {\n        __typename\n        ... on UserMigrationDefaults {\n          email\n          source\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query userMigrationDefaults {\n    userMigration {\n      defaults {\n        __typename\n        ... on UserMigrationDefaults {\n          email\n          source\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendAccountVerificationEmail(\n    $email: String!\n    $idempotencyKey: String!\n  ) {\n    userMigration {\n      sendAccountVerificationEmail(\n        email: $email\n        idempotencyKey: $idempotencyKey\n      ) {\n        __typename\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n        ... on UserMigrationVerificationEmailSent {\n          email\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation sendAccountVerificationEmail(\n    $email: String!\n    $idempotencyKey: String!\n  ) {\n    userMigration {\n      sendAccountVerificationEmail(\n        email: $email\n        idempotencyKey: $idempotencyKey\n      ) {\n        __typename\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n        ... on UserMigrationVerificationEmailSent {\n          email\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query verifyAccountEmail($email: String!) {\n    userMigration {\n      verifyAccountEmail(email: $email) {\n        __typename\n        ... on UserMigrationEmailVerified {\n          toastIdentityGuid\n          verifiedTime\n          toastAccountType\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query verifyAccountEmail($email: String!) {\n    userMigration {\n      verifyAccountEmail(email: $email) {\n        __typename\n        ... on UserMigrationEmailVerified {\n          toastIdentityGuid\n          verifiedTime\n          toastAccountType\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation confirmAccountVerificationEmail(\n    $email: String!\n    $confirmationKey: String!\n  ) {\n    userMigration {\n      confirmAccountVerificationEmail(\n        email: $email\n        confirmationKey: $confirmationKey\n      ) {\n        __typename\n        ... on UserMigrationEmailVerified {\n          toastIdentityGuid\n          verifiedTime\n          toastAccountType\n        }\n        ... on UserMigrationEmailNotVerified {\n          toastIdentityGuid\n          toastAccountType\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation confirmAccountVerificationEmail(\n    $email: String!\n    $confirmationKey: String!\n  ) {\n    userMigration {\n      confirmAccountVerificationEmail(\n        email: $email\n        confirmationKey: $confirmationKey\n      ) {\n        __typename\n        ... on UserMigrationEmailVerified {\n          toastIdentityGuid\n          verifiedTime\n          toastAccountType\n        }\n        ... on UserMigrationEmailNotVerified {\n          toastIdentityGuid\n          toastAccountType\n        }\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserAlreadyMigrated {\n          userId\n          toastIdentityGuid\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation migrate($email: String!, $password: String!) {\n    userMigration {\n      migrate(email: $email, password: $password) {\n        __typename\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserMigrationSuccess {\n          userId\n          toastIdentityGuid\n          email\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation migrate($email: String!, $password: String!) {\n    userMigration {\n      migrate(email: $email, password: $password) {\n        __typename\n        ... on UserMigrationFailed {\n          message\n        }\n        ... on UserMigrationSuccess {\n          userId\n          toastIdentityGuid\n          email\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;