import * as React from 'react'
import { Layout } from '../layout'
import { useTranslation } from '@local/translations'

export const ConfirmEmailView = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <div className='p-10'>
        <div className='w-2/3'>
          <h1 className='type-headline-2 mb-4'>{t('email.confirm.heading')}</h1>
          <p>{t('email.confirm.body')}</p>
        </div>
      </div>
    </Layout>
  )
}
