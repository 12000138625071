import * as React from 'react'
import { useEffect } from 'react'
import { useUserMigration } from '../UserMigrationContextProvider'
import {
  SendAccountVerificationEmail,
  VerifyAccountEmail
} from '@local/api/documents'
import { WaitForEmailVerification } from '../state'
import { VerifyEmailView } from './VerifyEmailView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { redirectMigratedUser } from '../functions'
import { useTranslation } from '@local/translations'

const verificationInterval = 3000

export const VerifyEmail = () => {
  const { state, dispatch } = useUserMigration<WaitForEmailVerification>()
  const [verificationTimeout, setVerificationTimeout] = React.useState(0)
  const client = useApolloClient()
  const { showErrorSnackBar, showSnackBar } = useSnackBar()
  const [checkEmailVerificationCounter, setCheckEmailVerificationCounter] =
    React.useState(0)
  const { t } = useTranslation()

  const checkAccountEmailVerification = async () => {
    const result = await client.query({
      query: VerifyAccountEmail,
      variables: {
        email: state.email
      },
      fetchPolicy: 'network-only'
    })

    const queryResult = result.data?.userMigration?.verifyAccountEmail

    switch (queryResult?.__typename) {
      case 'UserMigrationEmailVerified':
        dispatch({
          type: 'email-verified',
          email: state.email
        })
        return false
      case 'UserMigrationEmailNotVerified':
        return true
      case 'UserMigrationFailed':
        showErrorSnackBar(queryResult.message)
        return true
      case 'UserAlreadyMigrated':
        redirectMigratedUser()
        return false
      default:
        showErrorSnackBar(t('anErrorOccurredRetrying'))
        return true
    }
  }

  useEffect(() => {
    const checkVerification = async () => {
      clearTimeout(verificationTimeout)
      const shouldContinue = await checkAccountEmailVerification()
      if (shouldContinue) {
        const timeout = setTimeout(
          () => setCheckEmailVerificationCounter((value) => value + 1),
          verificationInterval
        ) as unknown as number
        setVerificationTimeout(timeout)
      }
    }
    checkVerification()
    return () => {
      clearTimeout(verificationTimeout)
    }
  }, [checkEmailVerificationCounter])

  const handleEditEmail = () => {
    dispatch({
      type: 'migration-defaults',
      email: state.email,
      source: state.source
    })
  }

  const handleResendEmail = async () => {
    const idempotencyKey = crypto.randomUUID()
    const result = await client.mutate({
      mutation: SendAccountVerificationEmail,
      variables: {
        email: state.email,
        idempotencyKey: idempotencyKey
      }
    })

    const mutationResult =
      result.data?.userMigration?.sendAccountVerificationEmail

    switch (mutationResult?.__typename) {
      case 'UserMigrationVerificationEmailSent':
        showSnackBar(`${t('email.verify.emailResentTo')} ${state.email}`)
        break
      case 'UserMigrationFailed':
        showErrorSnackBar(mutationResult.message)
        break
      case 'UserAlreadyMigrated':
        redirectMigratedUser()
        break
      default:
        showErrorSnackBar(t('anErrorOccurred'))
        break
    }
  }

  return (
    <VerifyEmailView
      email={state.email}
      onEditEmail={handleEditEmail}
      onResendEmail={handleResendEmail}
    />
  )
}
