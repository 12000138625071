import React, { useEffect } from 'react'
import { pathTemplate, useParamsFromRoute } from '../routes'
import { ConfirmAccountVerificationEmail } from '@local/api/documents'
import { useUserMigration } from '../UserMigrationContextProvider'
import { InitialState } from '../state'
import { Navigate } from 'react-router-dom'
import { ConfirmEmailView } from './ConfirmEmailView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { redirectMigratedUser } from '../functions'
import { useTranslation } from '@local/translations'

export const ConfirmEmail = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserMigration<InitialState>()
  const { email, confirmationId } = useParamsFromRoute('confirmEmail')
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()

  useEffect(() => {
    if (!email || !confirmationId) {
      return
    }
    const fetchData = async () => {
      const result = await client.mutate({
        mutation: ConfirmAccountVerificationEmail,
        variables: {
          email: email,
          confirmationKey: confirmationId
        },
        fetchPolicy: 'network-only'
      })

      const verification =
        result.data?.userMigration?.confirmAccountVerificationEmail

      switch (verification?.__typename) {
        case 'UserMigrationEmailVerified':
          dispatch({
            type: 'email-confirmed',
            email: email
          })
          break
        case 'UserMigrationEmailNotVerified':
          showErrorSnackBar(t('email.confirm.emailNotVerified'))
          break
        case 'UserMigrationFailed':
          if (verification.message === 'EmailAlreadyVerified') {
            dispatch({
              type: 'email-confirmed',
              email: email
            })
          } else {
            showErrorSnackBar(verification.message)
          }
          break
        case 'UserAlreadyMigrated':
          redirectMigratedUser()
          break
        default:
          showErrorSnackBar(t('anErrorOccurred'))
          break
      }
    }
    fetchData()
  }, [email, confirmationId, client, dispatch, showErrorSnackBar, t])

  if (state.type === 'initial-state') {
    return <ConfirmEmailView />
  } else {
    return <Navigate to={pathTemplate('index')} state={state} />
  }
}
