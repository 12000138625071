import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'
import { Portal, PortalProvider } from '@toasttab/ec-layout'
import * as React from 'react'
import { useTranslation } from '@local/translations'

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <PortalProvider value='data-udm'>
      <Portal
        id='page-body'
        component={
          <>
            <Error500DesktopIllustration />
            <h1>{t('oopsWeHaveSomeCleaningUpToDo')}</h1>
          </>
        }
      />
    </PortalProvider>
  )
}
