import * as React from 'react'
import cx from 'classnames'
import { ToastSquareLogo } from '../logo'
import { useCompanyCode } from '@toasttab/ec-session'
import { RestaurantIcon } from '@toasttab/buffet-pui-icons'
import { patternWizardTemplateIllustrationDataUrl } from '@toasttab/buffet-pui-illustrations'

export const Layout = (
  props: React.PropsWithChildren<{ questions?: React.ReactNode }>
) => {
  const companyCode = useCompanyCode()

  return (
    <>
      <div
        style={{ height: '64px', width: '100%' }}
        className='no-print flex justify-between items-stretch w-full border-b bg-gray-0'
      >
        <div className={cx('flex-none items-center shrink overflow-hidden')}>
          <ToastSquareLogo />
        </div>
        <RestaurantIcon
          accessibility='decorative'
          className='self-center pl-8'
        />
        <div className='flex-grow self-center pl-2'>{companyCode}</div>
      </div>
      <div className='bg-gray-0 md:p-8 flex flex-col md:flex-row'>
        <div className='bg-white lg:w-1/2 xl:w-3/5'>{props.children}</div>
        <div className='bg-gray-0 p-8'></div>
        <div
          className='lg:w-1/2 xl:w-2/5 pt-16'
          style={{ backgroundImage: patternWizardTemplateIllustrationDataUrl }}
        >
          {props.questions}
        </div>
      </div>
    </>
  )
}
