import * as React from 'react'

const ToastSquareLogo = () => {
  return (
    <span className='flex-none bg-brand-50 text-white h-16 w-16 inline-block overflow-hidden cursor-pointer'>
      <svg width='64px' height='64px' fill='white'>
        <g>
          <path
            d='M175.985 109.955c-.806 1.844-3.086 1.638-4.674 1.5-2.38-.204-4.718-.788-7.11-.86-2.116-.064-4.21.342-6.27.804-1.546.342-3.696 1.246-5.092.04-1.544-1.336-1.82-3.612-1.842-5.526-.018-1.656.236-3.522.494-5.16.168-1.056.522-2.07.908-3.066.556-1.43 1.372-2.288 1.412-2.244-.414-.436-.912-.668-1.192-1.268-1.056-2.246 1.58-4.144 3.024-4.8 5.046-2.296 12.11-2.068 16.89.88 1.488.916 4.378 4.08 1.676 5.652 1.666.798 2.088 3.206 2.272 4.92a30.385 30.385 0 01-.246 8.248c-.056.336-.14.626-.25.88zm3.704-3.772c.008-2.098-.05-4.218-.274-6.298-.15-1.414-.624-3.222-2.126-3.59.172.044.46.048.588-.02 1.746-.93 1.614-3.216.994-4.872-.812-2.168-2.74-3.702-4.652-4.724-6.858-3.666-16.04-3.36-22.692.816-1.732 1.088-3.724 3.21-2.85 5.562.222.594.614 1.108 1.038 1.568.196.208.66.694.66.696-.896 1.232-1.544 2.336-1.964 3.832-1.263 4.488-1.053 7.376-.706 10.982.214 2.23.94 5.56 3.474 5.924.002.002.006.002.01.002 2.062.29 4.304-.4 6.36-.646 2.274-.272 4.564-.698 6.856-.638 3.236.084 6.45.868 9.692.898 1.71.016 4.56.184 5.066-2.11.486-2.178.496-4.45.52-6.676l.006-.706'
            transform='translate(-132 -68)'
          />
        </g>
      </svg>
    </span>
  )
}

export default ToastSquareLogo
