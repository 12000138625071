import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Layout } from '../layout'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { Migrated } from '../state'
import { useCallback } from 'react'
import { useTranslation } from '@local/translations'

export const Complete = () => {
  const state = useUserMigrationState<Migrated>()
  const { t } = useTranslation()

  const handleContinue = useCallback(() => {
    if (state.returnUrl) {
      const encodedReturnUrl = encodeURIComponent(state.returnUrl)
      window.location.assign(`/Logout.aspx?returnUrl=${encodedReturnUrl}`)
    } else {
      window.location.assign('/Logout.aspx')
    }
  }, [state.returnUrl])

  return (
    <Layout>
      <div className='p-10 flex flex-col items-center'>
        <CelebrationIllustration />
        <h1 className='type-headline-2 mt-6'>{t('complete.great')}</h1>
        <h1 className='type-headline-2'>{t('complete.youAreAllSetUp')}</h1>
        <p className='mt-8 text-center max-w-sm'>
          <span className='font-bold'>{state.email}</span>{' '}
          {t('complete.isReadyToBeUsedToLogin')}
        </p>
      </div>
      <div className='shadow p-6 flex justify-end'>
        <Button
          testId='continue'
          onClick={handleContinue}
          type='button'
          iconRight={<ArrowForwardIcon aria-label={t('continue')} />}
        >
          {t('continue')}
        </Button>
      </div>
    </Layout>
  )
}
