import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Account } from './Account'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { PayrollEmail } from '../state'
import { useTranslation } from '@local/translations'

export const PayrollAccount = () => {
  const state = useUserMigrationState<PayrollEmail>()
  const { t } = useTranslation()

  return (
    <Account
      source='PAYROLL'
      email={state.email}
      heading={t('payrollAccount.heading')}
      body={t('payrollAccount.body')}
      questions={<Questions />}
    />
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <div className='space-y-6'>
      <div className='flex items-start space-x-6'>
        <QuestionAnswerDisplayIcon aria-label={t('questionAndAnswer')} />
        <div>
          <div className='font-semibold'>{t('payrollAccount.question1')}</div>
          <div className='type-subhead text-secondary'>
            {t('payrollAccount.answer1')}
          </div>
        </div>
      </div>
      <div className='flex items-start space-x-6'>
        <QuestionAnswerDisplayIcon aria-label={t('questionAndAnswer')} />
        <div>
          <div className='font-semibold'>{t('payrollAccount.question2')}</div>
          <div className='type-subhead text-secondary'>
            {t('payrollAccount.answer2')}
          </div>
        </div>
      </div>
    </div>
  )
}
