import { Layout } from '../layout'
import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { PasswordFieldWithRequirements } from '@toasttab/buffet-pui-forms'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from '@local/translations'

interface PasswordFormValues {
  password: string
}

export const PasswordView = (props: {
  onContinue: (password: string) => Promise<void>
}) => {
  const { onContinue } = props
  const { t } = useTranslation()

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required(t('password.passwordIsRequired'))
  })

  return (
    <Formik
      initialValues={{
        password: ''
      }}
      validationSchema={passwordSchema}
      onSubmit={async (values: PasswordFormValues) => {
        await onContinue(values.password)
      }}
    >
      <Form>
        <Layout>
          <div className='p-10'>
            <div className='w-2/3'>
              <h1 className='type-headline-2 mb-4'>
                {t('password.createANewPassword')}
              </h1>
              <p>{t('password.createANewPasswordToUse')}</p>
            </div>
            <hr className='mt-6 mb-6 h-px border-1 bg-gray-125' />
            <PasswordFieldWithRequirements
              label={t('password.confirmYourPassword')}
              name='password'
              placeholder={t('password.confirmYourPassword')}
            />
          </div>
          <div className='shadow p-6 flex justify-end'>
            <Button
              testId='continue'
              type='submit'
              iconRight={<ArrowForwardIcon aria-label={t('continue')} />}
            >
              {t('continue')}
            </Button>
          </div>
        </Layout>
      </Form>
    </Formik>
  )
}
