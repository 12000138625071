import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Account } from './Account'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { ToastEmail } from '../state'
import { useTranslation } from '@local/translations'

export const ToastAccount = () => {
  const { email } = useUserMigrationState<ToastEmail>()
  const { t } = useTranslation()

  return (
    <Account
      source='TOAST'
      email={email}
      heading={t('toastAccount.heading')}
      body={t('toastAccount.body')}
      questions={<Questions />}
    />
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <div className='space-y-6'>
      <div className='flex items-start space-x-6'>
        <QuestionAnswerDisplayIcon aria-label={t('questionAndAnswer')} />
        <div>
          <div className='font-semibold'>{t('toastAccount.question1')}</div>
          <div className='type-subhead text-secondary'>
            {t('toastAccount.answer1')}
          </div>
        </div>
      </div>
      <div className='flex items-start space-x-6'>
        <QuestionAnswerDisplayIcon aria-label={t('questionAndAnswer')} />
        <div>
          <div className='font-semibold'>{t('toastAccount.question2')}</div>
          <div className='type-subhead text-secondary'>
            {t('toastAccount.answer2')}
          </div>
        </div>
      </div>
    </div>
  )
}
