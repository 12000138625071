import * as React from 'react'
import { Layout } from '../layout'
import { EmailCard } from '../email'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { useTranslation } from '@local/translations'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'

export interface EmailFormValues {
  email: string
}

export const AccountView = (props: {
  questions?: React.ReactNode
  heading: React.ReactNode
  body: React.ReactNode
  email?: string
  onContinue: (email: string) => Promise<void>
}) => {
  const { questions, heading, body, email, onContinue } = props
  const { t } = useTranslation()
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validEmailRequired'))
      .required(t('validEmailRequired'))
  })

  return (
    <Formik
      initialValues={{
        email: email || ''
      }}
      validationSchema={emailSchema}
      onSubmit={async (values: EmailFormValues, actions) => {
        await onContinue(values.email)
        actions.setSubmitting(false)
      }}
    >
      <Form>
        <Layout questions={questions}>
          <div className='p-10'>
            <div className='w-2/3'>
              <h1 className='type-headline-2 mb-4'>{heading}</h1>
              <p>{body}</p>
            </div>
            <hr className='mt-6 mb-6 h-px border-1 bg-gray-125' />
            <EmailCard email={email} />
          </div>
          <div className='shadow p-6 flex justify-end'>
            <Button
              type='submit'
              testId='continue'
              iconRight={<ArrowForwardIcon aria-label={t('continue')} />}
            >
              {t('continue')}
            </Button>
          </div>
        </Layout>
      </Form>
    </Formik>
  )
}
