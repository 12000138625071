const deferMigration = async (
  returnUrl: string | null
): Promise<string | null> => {
  const url = returnUrl
    ? `/DeferMigration?returnUrl=${encodeURIComponent(returnUrl)}`
    : '/DeferMigration'
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const location = resp.url
  return location
}

export default deferMigration
